import httpClient from './http-client';

const lookup = (zipCode) => {
    if (zipCode) {
        const params = {
            query: {
                zip_code: zipCode
            }
        };

        return httpClient.invoke('address', 'lookup', params);
    } else {
        return Promise.resolve({ data: {} })
    }
};

export default {
    lookup
}