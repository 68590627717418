import httpClient from './http-client';

const list = (filter: any): Promise<any> => {
    const params = {
        query: filter
    };

    return httpClient.invoke('subscription', 'list', params);
};

const listChanges = (filter: any): Promise<any> => {
    const params = {
        query: filter
    };

    return httpClient.invoke('subscription', 'listChanges', params);
};

const add = (providerId, subscriberId: string, data): Promise<any> => {
    const params = {
        query: {
            providerId,
            subscriberId
        },
        data: data
    };

    return httpClient.invoke('subscription', 'add', params)
};

const update = (providerId, subscriberId: string, data): Promise<any> => {
    const params = {
        query: {
            providerId,
            subscriberId
        },
        data: data
    };

    return httpClient.invoke('subscription', 'update', params)
};

const changeStatus = (providerId, subscriberId: string, data): Promise<any> => {
    const params = {
        query: {
            providerId,
            subscriberId
        },
        data: data
    };

    return httpClient.invoke('subscription', 'changeStatus', params)
};

const viewPassword = (providerId, subscriberId, planProviderId): Promise<any> => {
    const params = {
        query: {
            providerId,
            subscriberId,
            planProviderId
        }
    };

    return httpClient.invoke('subscription', 'viewPassword', params)
};

export default {
    list,
    listChanges,
    add,
    update,
    changeStatus,
    viewPassword
}