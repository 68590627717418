import httpClient from './http-client';

const list = (filter: any): Promise<any> => {
    const params = {
        query: filter
    };

    return httpClient.invoke('provider', 'list', params);
};

const listServices = (filter: any): Promise<any> => {
    const params = {
        query: filter
    };

    return httpClient.invoke('provider', 'listServices', params);
};

const get = (providerId: string): Promise<any> => {
    const params = {
        query: {
            providerId: providerId
        }
    };

   return httpClient.invoke('provider', 'get', params)
};

const add = (data: any): Promise<any> => {
    const params = {
        data: data
    };

    return httpClient.invoke('provider', 'add', params)
};

const update = (providerId: string, data: any): Promise<any> => {
    const params = {
        query: {
            providerId: providerId
        },
        data: data
    };

    return httpClient.invoke('provider', 'update', params)
};

const remove = (providerId: string): Promise<any> => {
    const params = {
        query: {
            providerId: providerId
        }
    };

    return httpClient.invoke('provider', 'remove', params)
};

export default {
    list,
    listServices,
    get,
    add,
    update,
    remove
}