<template>
  <div>
    <v-text-field :label="label" v-mask="'##/##/####'" v-model="inputDate">
      <template v-slot:append>
        <v-menu v-model="menu" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-calendar</v-icon>
          </template>
          <v-date-picker
            :value="calendarDate"
            @input="selectedDate($event)"
            @click="menu = false"
          />
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: ["label", "value"],
  data() {
    return {
      menu: false,
      displayDate: null,
      inputDate: null,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.inputDate = moment(val).format("DD/MM/YYYY");
      }
    },
    inputDate(val) {
      if (val && val.length === 10) {
        this.selectedDate(moment(val, "DD/MM/YYYY").toDate());
      }
    },
  },
  computed: {
    calendarDate() {
      if (this.inputDate && this.inputDate.length === 10) {
        let date = moment(this.inputDate, "DD/MM/YYYY");

        if (date.isValid) {
          return date.format("YYYY-MM-DD");
        }
      }

      return null;
    },
  },
  methods: {
    selectedDate(date) {
      if (date) {
        this.$emit(`input`, date);
        this.menu = false;
      }
    },
  },
};
</script>