import httpClient from './http-client';

const list = (filter: any): Promise<any> => {
    const params = {
        query: filter
    };

    return httpClient.invoke('subscriptionPlan', 'list', params);
};

const get = (providerId, subscriptionPlanId: string): Promise<any> => {
    const params = {
        query: {
            providerId,
            subscriptionPlanId
        }
    };

    return httpClient.invoke('subscriptionPlan', 'get', params)
};

const add = (providerId, data: any): Promise<any> => {
    const params = {
        query: {
            providerId
        },
        data: data
    };

    return httpClient.invoke('subscriptionPlan', 'add', params)
};

const update = (providerId, subscriptionPlanId: string, data: any): Promise<any> => {
    const params = {
        query: {
            providerId,
            subscriptionPlanId
        },
        data: data
    };

    return httpClient.invoke('subscriptionPlan', 'update', params)
};

const remove = (providerId, subscriptionPlanId: string): Promise<any> => {
    const params = {
        query: {
            providerId,
            subscriptionPlanId
        }
    };

    return httpClient.invoke('subscriptionPlan', 'remove', params)
};

export default {
    list,
    get,
    add,
    update,
    remove
}