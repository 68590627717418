<template>
  <v-container v-if="selectedClient.id">
    <v-row>
      <v-col :cols="12">
        <span class="text-h5">Detalhe do assinante</span>
      </v-col>
    </v-row>

    <ButtonBar>
      <v-btn type="button" color="primary" outlined @click="goBack"
        >Voltar</v-btn
      >
      <v-btn
        type="button"
        color="error"
        @click="remove"
        v-if="!isNew && !isDeleted"
        v-auth="'SUBSCRIBER.REMOVE'"
        :loading="removeButton.loading"
        >Excluir</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="update"
        v-if="!isNew && !isDeleted"
        v-auth="'SUBSCRIBER.UPDATE'"
        :loading="updateButton.loading"
        >Alterar</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="add"
        v-if="isNew && !isDeleted"
        v-auth="'SUBSCRIBER.ADD'"
        :loading="addButton.loading"
        >Adicionar assinante</v-btn
      >
    </ButtonBar>
    <v-form ref="subscriberForm" v-model="validSubscriberForm">
      <v-row>
        <v-col cols="12" sm="6">
          <v-card>
            <v-card-title class="card-title">Dados básicos</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="CPF/CNPJ"
                    maxlength="18"
                    :value="subscriber.cpf | cpfCnpj"
                    @input="subscriber.cpf = $event"
                    required
                    :rules="[$rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <date-field
                    label="Data de nascimento"
                    v-model="subscriber.birthdate"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Primeiro nome"
                    maxlength="60"
                    v-model="subscriber.first_name"
                    required
                    :rules="[$rules.required, $rules.name]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Sobrenome"
                    maxlength="60"
                    v-model="subscriber.last_name"
                    required
                    :rules="[$rules.required, $rules.name]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Telefone"
                    maxlength="20"
                    v-mask="$masks.phone"
                    v-model="subscriber.phone"
                    required
                    :rules="[$rules.required]"
                    type="tel"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="WhatsApp"
                    maxlength="20"
                    v-mask="$masks.phone"
                    v-model="subscriber.whatsapp"
                    type="tel"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    type="text"
                    label="E-mail"
                    maxlength="100"
                    v-model="subscriber.email"
                    required
                    :rules="[$rules.required, $rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    type="text"
                    label="ID Externo (contrato)"
                    maxlength="100"
                    v-model="subscriber.external_id"
                    required
                    :rules="[$rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" v-if="module === 'BACKOFFICE'">
                  <v-checkbox
                    class="ml-4"
                    v-model="subscriber.test_user"
                    label="Usuário de teste"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" v-if="subscriber.deleted_at">
                  <v-text-field
                    type="text"
                    label="Data de exclusão"
                    :value="subscriber.deleted_at | defaultDate"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" v-if="subscriber.deleted_by_name">
                  <v-text-field
                    label="Excluído por"
                    v-model="subscriber.deleted_by_name"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card>
            <v-card-title class="card-title"
              >Endereço do assinante</v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col cols="4" sm="3">
                  <v-text-field
                    label="CEP"
                    maxlength="9"
                    required
                    :rules="[$rules.required]"
                    v-mask="$masks.zipCode"
                    v-model="subscriber.address.zip_code"
                    @change="lookupAddress"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Endereço"
                    maxlength="120"
                    v-model="subscriber.address.street"
                    required
                    :rules="[$rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="4">
                  <v-text-field
                    label="Número"
                    v-model="subscriber.address.number"
                    maxlength="10"
                  ></v-text-field>
                </v-col>
                <v-col :cols="8">
                  <v-text-field
                    label="Complemento"
                    v-model="subscriber.address.street2"
                    maxlength="30"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Bairro"
                    maxlength="40"
                    v-model="subscriber.address.district"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Cidade"
                    maxlength="60"
                    required
                    readonly
                    :rules="[$rules.required]"
                    v-model="subscriber.address.city"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    :items="data.states"
                    label="Estado"
                    v-model="subscriber.address.state"
                    item-value="id"
                    item-text="name"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- Hack para evitar autofill do Chrome -->
          <!-- <input
            type="email"
            name="hidden"
            class="hidden"
            style="width: 0; height: 0; border: 0; padding: 0; margin: 0"
          />
          <input
            type="password"
            name="hidden"
            class="hidden"
            style="width: 0; height: 0; border: 0; padding: 0; margin: 0"
          /> -->
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5"> </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5"
          >Assinaturas
          <v-btn
            fab
            color="primary accent-2"
            x-small
            @click="newSubscription"
            v-auth="'SUBSCRIBER.UPDATE'"
            v-if="data.availableProviders.length && !isDeleted"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </span>
      </v-col>
    </v-row>
    <v-form ref="subscriptionForm" v-model="validSubscriptionForm">
      <v-row v-if="!isNew">
        <v-col :cols="12">
          <v-card>
            <v-card-title class="card-title">Minhas assinaturas </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="subscriptionList.headers"
                :items="subscriptionList.data"
                :disable-sort="true"
                :loading="subscriptionList.loading"
                no-data-text="Nenhuma assinatura foi encontrada"
                loading-text="Carregando dados..."
                class="app-table hover-table"
                @click:row="subscriptionSelected"
                hide-default-footer
                v-if="!isNew"
              >
                <template v-slot:[`item.selected`]="{ item }">
                  <v-icon
                    color="success"
                    v-if="
                      item.plan_provider_id ===
                        selectedSubscription.plan_provider_id
                    "
                    >mdi-checkbox-marked-circle</v-icon
                  >
                </template>
                <template v-slot:[`item.plan`]="{ item }">
                  <span class="list-item-title"
                    >{{ item.subscription_plan_name }} </span
                  ><br />
                  <span class="list-item-subtitle">{{
                    item.plan_provider_name
                  }}</span>
                </template>
                <template v-slot:[`item.subscribed_at`]="{ item }">
                  {{ item.subscribed_at | defaultDate("DD/MM/YYYY HH:mm") }}
                </template>
                <template v-slot:[`item.expires_at`]="{ item }">
                  {{ item.expires_at | defaultDate("DD/MM/YYYY HH:mm") }}
                </template>
                <template v-slot:[`item.subscription_status`]="{ item }">
                  <v-chip
                    small
                    :color="
                      item.subscription_status
                        | enum(data.subscriptionPlanStatus, 'id', 'class')
                    "
                    dark
                    >{{
                      item.subscription_status
                        | enum(data.subscriptionPlanStatus)
                    }}</v-chip
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <ButtonBar>
        <v-btn
          type="button"
          color="error"
          @click="disableSubscription"
          v-if="
            !isNew &&
              selectedSubscription.subscription_status === 'A' &&
              !isDeleted
          "
          v-auth="'SUBSCRIBER.UPDATE'"
          :loading="disableSubscriptionButton.loading"
          >Cancelar assinatura</v-btn
        >
        <v-btn
          type="button"
          color="primary"
          @click="enableSubscription"
          v-if="
            !isNew &&
              selectedSubscription.subscription_status === 'I' &&
              !isDeleted
          "
          v-auth="'SUBSCRIBER.UPDATE'"
          :loading="enableSubscriptionButton.loading"
          >Ativar assinatura</v-btn
        >
        <v-btn
          type="button"
          color="primary"
          @click="updateSubscription"
          v-if="!isNew && !isAddingSubscription && !isDeleted"
          v-auth="'SUBSCRIBER.UPDATE'"
          :loading="updateSubscriptionButton.loading"
          >Alterar assinatura</v-btn
        >
        <v-btn
          type="button"
          color="primary"
          @click="addSubscription"
          v-if="!isNew && isAddingSubscription"
          v-auth="'SUBSCRIBER.UPDATE'"
          :loading="addSubscriptionButton.loading"
          >Adicionar assinatura</v-btn
        >
      </ButtonBar>

      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="7">
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title class="card-title"
                      >Dados do plano</v-card-title
                    >
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          v-if="!isNew && isAddingSubscription"
                        >
                          <v-select
                            :items="data.availableProviders"
                            label="Provedor"
                            v-model="selectedSubscription.plan_provider_id"
                            required
                            item-value="id"
                            item-text="name"
                            :rules="[$rules.required]"
                            @change="availableProviderSelected"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="!isAddingSubscription">
                          <v-text-field
                            label="Provedor"
                            disabled
                            v-model="selectedSubscription.plan_provider_name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-autocomplete
                            :items="data.subscriptionPlans"
                            label="Plano"
                            v-model="selectedSubscription.subscription_plan_id"
                            required
                            :rules="[$rules.required]"
                            item-value="id"
                            item-text="full_name"
                          >
                          </v-autocomplete>
                        </v-col>
                        <!-- <v-col
                          cols="12"
                          sm="2"
                          v-if="!isNew && !isAddingSubscription"
                        >
                          <v-text-field
                            label="Ativação"
                            disabled
                            :value="
                              selectedSubscription.subscribed_at | defaultDate
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          v-if="
                            !isNew &&
                            !isAddingSubscription &&
                            module === 'BACKOFFICE'
                          "
                        >
                          <v-text-field
                            label="Validade"
                            disabled
                            :value="
                              selectedSubscription.expires_at | defaultDate
                            "
                          ></v-text-field>
                        </v-col> -->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="isMainSubscription">
                <v-col cols="12">
                  <v-card>
                    <v-card-title class="card-title"
                      >Dados do pagamento</v-card-title
                    >
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-select
                            :items="['', 5, 8, 10, 15]"
                            label="Dia do pagamento"
                            v-model="selectedSubscription.payment_day"
                            required
                            :rules="[$rules.required]"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="isMainSubscription">
                <v-col cols="12">
                  <v-card>
                    <v-card-title class="card-title"
                      >Endereço de instalação</v-card-title
                    >
                    <v-card-text>
                      <v-row>
                        <v-col cols="6" sm="4">
                          <v-text-field
                            label="CEP"
                            maxlength="9"
                            v-mask="$masks.zipCode"
                            v-model="
                              selectedSubscription.installation_address.zip_code
                            "
                            @change="
                              lookupInstallationAddress(
                                selectedSubscription.installation_address
                                  .zip_code
                              )
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="8">
                          <v-text-field
                            label="Endereço"
                            maxlength="120"
                            required
                            readonly
                            v-model="
                              selectedSubscription.installation_address.street
                            "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" sm="4">
                          <v-text-field
                            label="Número"
                            v-model="
                              selectedSubscription.installation_address.number
                            "
                            maxlength="10"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="8">
                          <v-text-field
                            label="Complemento"
                            v-model="
                              selectedSubscription.installation_address.street2
                            "
                            maxlength="30"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            label="Bairro"
                            maxlength="40"
                            v-model="
                              selectedSubscription.installation_address.district
                            "
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            label="Cidade"
                            maxlength="60"
                            required
                            readonly
                            v-model="
                              selectedSubscription.installation_address.city
                            "
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-select
                            :items="data.states"
                            label="Estado"
                            v-model="
                              selectedSubscription.installation_address.state
                            "
                            item-value="id"
                            item-text="name"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5">
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title class="card-title"
                      >Dados de acesso</v-card-title
                    >
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            label="Usuário"
                            maxlength="30"
                            v-model="selectedSubscription.username"
                            required
                            :rules="[$rules.required, $rules.username]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            label="Senha"
                            maxlength="60"
                            v-model="selectedSubscription.password"
                            :required="isAddingSubscription"
                            :rules="
                              isAddingSubscription ? [$rules.required] : []
                            "
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="
                              showPassword && selectedSubscription.password
                                ? 'mdi-eye-off'
                                : 'mdi-eye'
                            "
                            @click:append="togglePassword"
                            :loading="togglePasswordButton.loading"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            label="Código PIN"
                            maxlength="4"
                            v-model="selectedSubscription.pin_code"
                            :rules="[
                              $rules.number(4, 'Código precisa ter 4 dígitos'),
                            ]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="!isNew" v-auth="'SUBSCRIPTION.LIST_CHANGES'">
                <v-col cols="12">
                  <v-card>
                    <v-card-title class="card-title">Histórico </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="historyList.headers"
                        :items="historyList.data"
                        :disable-sort="true"
                        no-data-text="Nenhuma assinatura foi encontrada"
                        loading-text="Carregando dados..."
                        class="app-table"
                        hide-default-footer
                        v-if="!isNew"
                      >
                        <template v-slot:[`item.plan`]="{ item }">
                          <span class="list-item-subtitle">
                            {{ item.subscription_plan_name }}
                          </span>
                        </template>
                        <template v-slot:[`item.event_date`]="{ item }">
                          <span class="list-item-subtitle">
                            {{
                              item.event_date | defaultDate("DD/MM/YYYY HH:mm")
                            }}
                          </span>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                          <v-chip
                            small
                            :color="
                              item.status
                                | enum(
                                  data.subscriptionPlanStatus,
                                  'id',
                                  'class'
                                )
                            "
                            dark
                            >{{
                              item.status | enum(data.subscriptionPlanStatus)
                            }}</v-chip
                          >
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="!isNew && isMainSubscription"
            v-auth="'STREAMING_DEVICE.LIST'"
          >
            <v-col :cols="12">
              <v-card>
                <v-card-title class="card-title"
                  >Dispositivos
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        fab
                        color="primary accent-2"
                        x-small
                        @click="syncDevices"
                        :loading="syncDevicesButton.loading"
                        v-auth="'SUBSCRIBER.UPDATE'"
                      >
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Sincronizar</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="deviceList.headers"
                    :items="data.subscriptionDevices"
                    :items-per-page="-1"
                    :disable-sort="true"
                    :loading="deviceList.loading"
                    no-data-text="Nenhum dispositivo foi encontrado"
                    loading-text="Carregando dados..."
                    class="app-table hover-table"
                    @click:row="viewDevice"
                    hide-default-footer
                  >
                    <template v-slot:[`item.address`]="{ item }">
                      <span class="list-item-title">{{ item.mac_address }}</span
                      ><br />
                      <span class="list-item-subtitle">{{ item.ip }}</span>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      <v-chip
                        small
                        :color="
                          item.status
                            | enum(data.streamingDeviceStatus, 'id', 'class')
                        "
                        dark
                        >{{
                          item.status | enum(data.streamingDeviceStatus)
                        }}</v-chip
                      >
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import authApi from "@/common/api/auth.api";
import streamingDeviceApi from "@/common/api/streaming-device.api";
import addressApi from "@/common/api/address.api";
import providerApi from "@/common/api/provider.api";
import subscriberApi from "@/common/api/subscriber.api";
import subscriptionPlanApi from "@/common/api/subscription-plan.api";
import subscriptionApi from "@/common/api/subscription.api";
import clientApi from "@/common/api/client.api";
import userApi from "@/common/api/user.api";
import syncApi from "@/common/api/sync.api";
import subscriberStatus from "@/common/data/subscriber-status.data";
import subscriptionPlanStatus from "@/common/data/subscription-plan-status.data";
import streamingDeviceStatus from "@/common/data/streaming-device-status.data";
import states from "@/common/data/states.data";
import toaster from "@/common/util/toaster";
import ButtonBar from "@/components/layout/ButtonBar";
import DateField from "@/components/DateField.vue";

export default {
  name: "SubscriberDetails",

  components: { ButtonBar, DateField },

  data() {
    return {
      data: {
        subscriptionDevices: [],
        subscriptionPlans: [],
        availableProviders: [],
        states: states,
        subscriberStatus: subscriberStatus,
        subscriptionPlanStatus: subscriptionPlanStatus,
        streamingDeviceStatus: streamingDeviceStatus,
      },
      validSubscriberForm: true,
      validSubscriptionForm: true,
      isNew: true,
      isAddingSubscription: true,
      selectedSubscription: {
        installation_address: { street: "" },
      },
      subscriber: {
        cpf: "",
        address: { street: "" },
        installation_address: { street: "" },
        subscription_status: "A",
      },
      subscriptionList: {
        headers: [],
        data: [],
        loading: false,
      },
      historyList: {
        headers: [
          { text: "Plano", value: "plan" },
          { text: "Data do evento", value: "event_date" },
          { text: "Status", value: "status" },
        ],
        data: [],
      },
      showPassword: true,
      deviceList: {
        headers: [
          { text: "Modelo", value: "model" },
          { text: "Identificador", value: "identifier" },
          { text: "MAC Address / IP", value: "address" },
          { text: "Serial", value: "serial_number" },
          { text: "Status", value: "status" },
        ],
        loading: false,
      },
      removeButton: {
        loading: false,
      },
      updateButton: {
        loading: false,
      },
      addButton: {
        loading: false,
      },
      togglePasswordButton: {
        loading: false,
      },
      enableSubscriptionButton: {
        loading: false,
      },
      disableSubscriptionButton: {
        loading: false,
      },
      addSubscriptionButton: {
        loading: false,
      },
      updateSubscriptionButton: {
        loading: false,
      },
      syncDevicesButton: {
        loading: false,
      },
    };
  },

  created() {
    this.load();
    this.buildSubscriptionHeaders();
  },

  computed: {
    module() {
      return userApi.getUserModule();
    },

    isDeleted() {
      return !!this.subscriber.deleted_at;
    },

    selectedClient() {
      return clientApi.getSelectedClient();
    },

    subscriptionChangeLabel() {
      return this.selectedSubscription.subscription_status === "A"
        ? "Cancelar assinatura"
        : "Ativar assinatura";
    },

    isMainSubscription() {
      return this.selectedSubscription.plan_provider_type === "isp";
    },
  },

  methods: {
    buildSubscriptionHeaders() {
      this.subscriptionList.headers.push({ value: "selected", width: "50px" });
      this.subscriptionList.headers.push({ text: "Plano", value: "plan" });
      this.subscriptionList.headers.push({
        text: "Data de ativação",
        value: "subscribed_at",
      });

      if (userApi.getUserModule() === "BACKOFFICE") {
        this.subscriptionList.headers.push({
          text: "Data de cancelamento",
          value: "expires_at",
        });
      }

      this.subscriptionList.headers.push({
        text: "Status",
        value: "subscription_status",
      });
    },

    listSubscriptionPlans(plan_provider_id) {
      let filter = {
        providerId: plan_provider_id,
        status: "A",
        size: -1,
      };

      subscriptionPlanApi.list(filter).then((result) => {
        this.data.subscriptionPlans = result.data.data;
      });
    },

    load() {
      let subscriberId = this.$route.params.id;

      if (subscriberId !== "novo") {
        this.isNew = false;

        this.listSubscriptions();

        // carrega os dados imediatamente
        this.deviceList.loading = true;
        subscriberApi
          .get(this.selectedClient.id, subscriberId)
          .then((result) => {
            this.deviceList.loading = false;
            this.subscriber = result.data;
          })
          .catch(() => (this.deviceList.loading = false));
      } else {
        this.selectedSubscription.plan_provider_id = this.selectedClient.id;
        this.selectedSubscription.plan_provider_type = this.selectedClient.type;
        this.selectedSubscription.plan_provider_name = this.selectedClient.name;
        this.listSubscriptionPlans(this.selectedClient.id);
      }
    },

    togglePassword() {
      if (!this.selectedSubscription.password) {
        this.togglePasswordButton.loading = true;

        subscriptionApi
          .viewPassword(
            this.selectedClient.id,
            this.subscriber.id,
            this.selectedSubscription.plan_provider_id
          )
          .then((result) => {
            this.togglePasswordButton.loading = false;
            this.selectedSubscription.password = result.data;
            this.showPassword = !this.showPassword;
          })
          .catch(() => (this.togglePasswordButton.loading = false));
      } else {
        this.showPassword = !this.showPassword;
      }
    },

    listSubscriptions() {
      let subscriberId = this.$route.params.id;

      let filter = {
        providerId: this.selectedClient.id,
        subscriberId: subscriberId,
      };

      subscriptionApi.list(filter).then((result) => {
        this.subscriptionList.data = result.data;

        this.listAvailableProviders();

        if (result.data.length) {
          this.isAddingSubscription = false;

          this.subscriptionSelected(result.data[0]);
        }
      });
    },

    listAvailableProviders() {
      let filter = {
        status: "A",
        type: "service",
        size: -1,
      };

      providerApi.listServices(filter).then((result) => {
        this.data.availableProviders = result.data.data.filter((provider) => {
          return !this.subscriptionList.data.find(
            (subscription) => subscription.plan_provider_id === provider.id
          );
        });
      });
    },

    subscriptionSelected(item) {
      this.isAddingSubscription = false;

      this.listSubscriptionPlans(item.plan_provider_id);
      this.listSubscriberDevices(item.plan_provider_id);

      this.selectedSubscription = this.subscriptionList.data.find(
        (subscription) =>
          subscription.plan_provider_id === item.plan_provider_id
      );

      if (authApi.hasPermission("SUBSCRIPTION.LIST_CHANGES")) {
        this.historyList.data = this.selectedSubscription.history.filter(
          (item) => item.plan_provider_id === item.plan_provider_id
        );
      }
    },

    availableProviderSelected(item) {
      let selectedAvailableProvider = this.data.availableProviders.find(
        (availableProvider) => availableProvider.id === item
      );

      selectedAvailableProvider.plan_provider_type =
        selectedAvailableProvider.type;
      this.listSubscriptionPlans(selectedAvailableProvider.id);
    },

    async lookupAddress() {
      let result = await addressApi.lookup(this.subscriber.address.zip_code);

      if (result.data) {
        this.subscriber.address.street = result.data.street;
        this.subscriber.address.district = result.data.district;
        this.subscriber.address.city = result.data.city;
        this.subscriber.address.state = result.data.state;
      }
    },

    async lookupInstallationAddress(value) {
      let result = await addressApi.lookup(value);

      if (result.data) {
        this.selectedSubscription.installation_address.street =
          result.data.street;
        this.selectedSubscription.installation_address.street =
          result.data.street;
        this.selectedSubscription.installation_address.district =
          result.data.district;
        this.selectedSubscription.installation_address.city = result.data.city;
        this.selectedSubscription.installation_address.state =
          result.data.state;
      }
    },

    goBack() {
      this.$router.push(`/assinantes`);
    },

    viewDevice(device) {
      this.$router.push(`/dispositivos/${device.id}`);
    },

    async add() {
      this.$refs.subscriberForm.validate();
      this.$refs.subscriptionForm.validate();

      if (
        await this.$root.$confirm(
          this.validSubscriberForm && this.validSubscriptionForm,
          "subscriber.add"
        )
      ) {
        let data = {
          plan_provider_id: this.selectedSubscription.plan_provider_id,
          subscription_plan_id: this.selectedSubscription.subscription_plan_id,
          account_number: this.selectedSubscription.account_number,
          username: this.selectedSubscription.username.toUpperCase(),
          password: this.selectedSubscription.password,
          first_name: this.subscriber.first_name,
          last_name: this.subscriber.last_name,
          cpf: this.subscriber.cpf,
          birthdate: this.subscriber.birthdate,
          email: this.subscriber.email,
          external_id: this.subscriber.external_id,
          phone: this.subscriber.phone,
          whatsapp: this.subscriber.whatsapp,
          test_user: this.subscriber.test_user,
          payment_day: this.selectedSubscription.payment_day,
          address: {
            street: this.subscriber.address.street,
            number: this.subscriber.address.number,
            street2: this.subscriber.address.street2,
            zip_code: this.subscriber.address.zip_code,
            district: this.subscriber.address.district,
            city: this.subscriber.address.city,
            state: this.subscriber.address.state,
          },
          installation_address: {
            street: this.selectedSubscription.installation_address.street,
            number: this.selectedSubscription.installation_address.number,
            street2: this.selectedSubscription.installation_address.street2,
            zip_code: this.selectedSubscription.installation_address.zip_code,
            district: this.selectedSubscription.installation_address.district,
            city: this.selectedSubscription.installation_address.city,
            state: this.selectedSubscription.installation_address.state,
          },
        };

        let subscriptionPlan = this.data.subscriptionPlans.find(
          (item) => item.id === this.selectedSubscription.subscription_plan_id
        );

        if (subscriptionPlan) {
          data.subscription_plan_external_id = subscriptionPlan.external_id;
        }

        this.addButton.loading = true;

        subscriberApi
          .add(this.selectedClient.id, data)
          .then(() => {
            this.addButton.loading = false;

            toaster.show("Assinante adicionado com sucesso!");
            this.goBack();
          })
          .catch(() => (this.addButton.loading = false));
      }
    },

    async update() {
      this.$refs.subscriberForm.validate();

      if (
        await this.$root.$confirm(this.validSubscriberForm, "subscriber.update")
      ) {
        let data = {
          external_subscriber_id: this.subscriber.external_subscriber_id,
          first_name: this.subscriber.first_name,
          last_name: this.subscriber.last_name,
          cpf: this.subscriber.cpf,
          birthdate: this.subscriber.birthdate,
          email: this.subscriber.email,
          external_id: this.subscriber.external_id,
          phone: this.subscriber.phone,
          whatsapp: this.subscriber.whatsapp,
          test_user: this.subscriber.test_user,
          address_id: this.subscriber.address_id,
          address: {
            street: this.subscriber.address.street,
            number: this.subscriber.address.number,
            street2: this.subscriber.address.street2,
            zip_code: this.subscriber.address.zip_code,
            district: this.subscriber.address.district,
            city: this.subscriber.address.city,
            state: this.subscriber.address.state,
          },
        };

        this.updateButton.loading = true;

        subscriberApi
          .update(this.selectedClient.id, this.subscriber.id, data)
          .then(() => {
            this.updateButton.loading = false;

            toaster.show("Assinante alterado com sucesso!");
            this.goBack();
          })
          .catch(() => (this.updateButton.loading = false));
      }
    },

    async remove() {
      if (await this.$root.$confirm(true, "subscriber.remove")) {
        this.removeButton.loading = true;

        subscriberApi
          .remove(this.selectedClient.id, this.subscriber.id)
          .then(() => {
            this.removeButton.loading = false;

            toaster.show("Assinante excluído com sucesso!");
            this.goBack();
          })
          .catch(() => (this.removeButton.loading = false));
      }
    },

    async enableSubscription() {
      if (await this.$root.$confirm(true, "subscription.enable")) {
        let data = {
          planProviderId: this.selectedSubscription.plan_provider_id,
          action: "enable",
        };

        this.enableSubscriptionButton.loading = true;

        subscriptionApi
          .changeStatus(this.selectedClient.id, this.subscriber.id, data)
          .then(() => {
            this.enableSubscriptionButton.loading = false;

            this.listSubscriptions();

            toaster.show(`Assinatura ativada com sucesso!`);
          })
          .catch(() => (this.enableSubscriptionButton.loading = false));
      }
    },

    async disableSubscription() {
      if (await this.$root.$confirm(true, "subscription.disable")) {
        let data = {
          planProviderId: this.selectedSubscription.plan_provider_id,
          action: "disable",
        };

        this.disableSubscriptionButton.loading = true;

        subscriptionApi
          .changeStatus(this.selectedClient.id, this.subscriber.id, data)
          .then(() => {
            this.disableSubscriptionButton.loading = false;

            this.listSubscriptions();

            toaster.show(`Assinatura cancelada com sucesso!`);
          })
          .catch(() => (this.disableSubscriptionButton.loading = false));
      }
    },

    async newSubscription() {
      this.isAddingSubscription = true;
      this.selectedSubscription = {};
      this.data.subscriptionPlans = [];
    },

    async addSubscription() {
      this.$refs.subscriptionForm.validate();

      if (
        await this.$root.$confirm(
          this.validSubscriptionForm,
          "subscription.add"
        )
      ) {
        let data = {
          plan_provider_id: this.selectedSubscription.plan_provider_id,
          subscription_plan_id: this.selectedSubscription.subscription_plan_id,
          external_subscriber_id: this.subscriber.external_subscriber_id,
          account_number: this.selectedSubscription.account_number,
          pin_code: this.selectedSubscription.pin_code,
          username: this.selectedSubscription.username,
          password: this.selectedSubscription.password,
          installation_address:
            this.selectedSubscription.installation_address || {},
          subscription_status: "A",
          payment_day: this.selectedSubscription.payment_day,
        };

        let subscriptionPlan = this.data.subscriptionPlans.find(
          (item) => item.id === this.selectedSubscription.subscription_plan_id
        );

        if (subscriptionPlan) {
          data.subscription_plan_external_id = subscriptionPlan.external_id;
        }

        this.addSubscriptionButton.loading = true;

        subscriptionApi
          .add(this.selectedClient.id, this.subscriber.id, data)
          .then(() => {
            this.addSubscriptionButton.loading = false;

            this.listSubscriptions();

            toaster.show(`Assinatura ativada com sucesso!`);
          })
          .catch(() => (this.addSubscriptionButton.loading = false));
      }
    },

    async updateSubscription() {
      this.$refs.subscriptionForm.validate();

      if (
        await this.$root.$confirm(
          this.validSubscriptionForm,
          "subscription.update"
        )
      ) {
        let data = {
          plan_provider_id: this.selectedSubscription.plan_provider_id,
          subscription_plan_id: this.selectedSubscription.subscription_plan_id,
          external_subscriber_id: this.selectedSubscription
            .external_subscriber_id,
          account_number: this.selectedSubscription.account_number,
          pin_code: this.selectedSubscription.pin_code,
          username: this.selectedSubscription.username,
          password: this.selectedSubscription.password,
          installation_address: this.selectedSubscription.installation_address,
          payment_day: this.selectedSubscription.payment_day,
        };

        let subscriptionPlan = this.data.subscriptionPlans.find(
          (item) => item.id === this.selectedSubscription.subscription_plan_id
        );

        if (subscriptionPlan) {
          data.subscription_plan_external_id = subscriptionPlan.external_id;
        }

        this.updateSubscriptionButton.loading = true;

        subscriptionApi
          .update(this.selectedClient.id, this.subscriber.id, data)
          .then(() => {
            this.updateSubscriptionButton.loading = false;

            this.listSubscriptions();

            toaster.show(`Assinatura alterada com sucesso!`);
          })
          .catch(() => (this.updateSubscriptionButton.loading = false));
      }
    },

    listSubscriberDevices(providerId) {
      if (authApi.hasPermission("STREAMING_DEVICE.LIST")) {
        let filter = {
          size: 200,
          providerId: providerId,
          subscriber_id: this.$route.params.id,
        };

        streamingDeviceApi.list(filter).then((result) => {
          this.data.subscriptionDevices = result.data.data;
        });
      }
    },

    async syncDevices() {
      if (await this.$root.$confirm(true, "sync.syncSubscriberDevices")) {
        this.syncDevicesButton.loading = true;

        syncApi
          .syncSubscriberDevices(
            this.selectedSubscription.plan_provider_id,
            this.subscriber.id
          )
          .then(() => {
            this.syncDevicesButton.loading = false;

            this.listSubscriberDevices();

            toaster.show(`Dispositivos sincronizados com sucesso!`);
          })
          .catch(() => (this.syncDevicesButton.loading = false));
      }
    },
  },
};
</script>

<style lang="scss"></style>
