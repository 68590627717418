const data = [
    {
        id: 'A',
        name: 'Ativo',
        class: 'green'
    },
    {
        id: 'I',
        name: 'Inativo',
        class: 'red'
    },
    {
        id: 'C',
        name: 'Excluído pelo IPTV',
        class: '#40241a'
    },
    {
        id: 'D',
        name: 'Excluído do Nora',
        class: 'grey'
    },
    {
        id: 'T',
        name: 'Aguardando Ativação',
        class: 'orange'
    }
    // {
    //     id: 'O',
    //     name: 'On hold',
    //     class: 'orange'
    // }
];

export default [...data];